import React from "react";
import {useMount, useSetState} from "ahooks";
import {failOrderCallBack, getRechargeInfo} from "@api/add_oil";
import Tools from "@utils/tools";
import {Button, List, Result} from "antd-mobile";
import {getSessionStorage} from "@utils/my_session";
import wx from 'weixin-js-sdk-ts'

const PayResultOrder = ()=> {
  const urlParam = Tools.param2Obj(window.location.search);
  const [orderInfo, setOrderInfo] = useSetState<any>({
    money: 0,
    ordersn: "",
    room: {
      name: ''
    }
  });

  useMount(()=> {
    getOrderInfo();
  })

  const sendOk = ()=> {
    if (Tools.getSystemPayEnv() === 'wx') {
      wx.closeWindow()
    } else {
      // 支付宝关闭不了
      // (window as any).ap.popWindow();
      (window as any).AlipayJSBridge.call('popWindow');
    }
  }

  const getOrderInfo = async() => {
    try {
      if (urlParam.hasOwnProperty('paysn')) {
        if (urlParam.status === '0') await failOrderCallBack(urlParam.paysn)
        const res = await getRechargeInfo(getSessionStorage('app_id'), urlParam.paysn);
        if (res.code === 200) {
          setOrderInfo(res.data);
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <Result status={urlParam.status === '0' ? 'error':'success'} title={urlParam.status === '0' ? '支付失败':'支付成功'}/>
      <List>
        <List.Item extra={orderInfo.ordersn}>订单号</List.Item>
        <List.Item extra={orderInfo.money}>充值金额</List.Item>
        <List.Item extra={orderInfo.room.name}>当前门店</List.Item>
      </List>
      <div style={{padding: '0 10px', marginTop: '10px'}} onClick={()=> sendOk()}>
        <Button type={'button'} block color={'primary'}>完成</Button>
      </div>
    </div>
  )
}

export default PayResultOrder;