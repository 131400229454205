import React, { FC, useState } from "react";
import { useMount } from 'ahooks'
import {Toast,  NumberKeyboard} from 'antd-mobile'
import Tools from '@utils/tools'
import {useNavigate} from "react-router-dom";
import PayUtils from '@utils/pay_utils';
import {getSessionStorage} from "@utils/my_session";
import {wxOpenCardUrl, createOilOrder, getButtonByNameStatus, getMyStoreInfo} from "@api/add_oil";
import openCardImg from "@assets/images/card_3.png";
import add_oil_img from "@assets/images/add_oil_img.png";
import './index.scss'

const AddOilPay: FC = () => {
  const urlParam = Tools.param2Obj(window.location.search);
  const naviagtor = useNavigate();
  const [isShowStatus, setShowStatus] = useState(false) // 是否自愿开卡
  const [visibleKey, setVisibleKey] = useState(true)
  const [priceMoney, setPriceMoney] = useState('')
  const [openCardUrl, setOpenCardUrl] = useState('')
  const [storeInfo, setStoreInfo] = useState({
    room: {
      delete_time: "",
      name: "",
      mobile: "",
      image: add_oil_img
    },
    emp: {
      id: "",
      name: "",
      mobile: ""
    }
  })

  useMount(async ()=> {
    if (Tools.getSystemPayEnv()==='wx') {
      openStatus()
    }
    await getUserStoreInfo()
    console.log('app_token'+urlParam.app_token, urlParam.app_token !== ' ')
  })

  const getParam = ()=> {
    let obj = {
      app_id: getSessionStorage('app_id'),
      qrcode_id: getSessionStorage('qrcode_id'),
      wx_token: getSessionStorage('wx_token'),
      source: Tools.getSystemPayEnv(),
      money: priceMoney
    }
    if (getSessionStorage('emp_id')) {
      obj = Object.assign(obj, {
        emp_id: getSessionStorage('emp_id')
      })
    }
    if (Tools.getSystemPayEnv() === 'ali') {
      obj = Object.assign(obj, {
        ali_token: getSessionStorage('ali_token')
      })
      delete obj.wx_token
    }
    return obj
  }

  const getUserStoreInfo = async ()=> {
    const res = await getMyStoreInfo({
      app_id: urlParam.app_id ? urlParam.app_id : '',
      qrcode_id: urlParam.qrcode_id ? urlParam.qrcode_id: '',
      room_id: urlParam.room_id ? urlParam.room_id:'',
      emp_id: urlParam.emp_id ? urlParam.emp_id:''
    })
    if (res.code === 200) {
      setStoreInfo(res.data)
    }
  }

  const openCard = ()=> {
    window.location.href = openCardUrl
  }

  const inputVal = (val: string)=> {
    setPriceMoney(priceMoney+val)
    if (Number(priceMoney) > 0 && !Tools.checkMoneyStr(priceMoney)) {
      Toast.show('请输入正确金额')
      setPriceMoney('')
    }
  }

  const delNumber = () => {
    Tools.vibrateShort()
    if (priceMoney.length > 0) {
      let price = priceMoney.substring(0, priceMoney.length - 1);
      setPriceMoney(price)
    }
  }

  const makeMoney = async ()=> {
    if (!Tools.checkMoneyStr(priceMoney)) {
      setPriceMoney('')
      return Toast.show('请输入正确金额!');
    }
    Toast.show({icon: 'loading', content: '请稍候...'})
    // 提交订单
    await pushOrderInfo()
  }

  const openStatus = async ()=> {
    const res = await getButtonByNameStatus(urlParam.app_id, 'must_register')
    if (res.code === 200) {
      const url = await isUserLoginStatus()
      if (res.data.status === 0) {
        if (getSessionStorage('app_token') !== '0') {
          setShowStatus(false)
        } else {
          setShowStatus(true)
        }
      } else {
        if (getSessionStorage('app_token') !== '0') {
          setShowStatus(false)
        } else {
          if (url) window.location.href = url
        }
      }
    }
  }

  // 是否强制登录
  const isUserLoginStatus = async ()=> {
    const res = await wxOpenCardUrl(getSessionStorage('app_id'), window.location.href)
    if (res.code === 200) {
      setOpenCardUrl(res.data.card_url)
      return res.data.card_url
    } else return false
  }

  // 提交订单
  const pushOrderInfo = async()=> {
    try {
      const res = await createOilOrder(getParam())
      if (res.code === 200) {
        // 立即支付
        if (Tools.getSystemPayEnv() === 'wx') {
          await PayUtils.wxPay(res.data)
        } else {
         const res_alipay = await PayUtils.aliPay(res.data.targetOrderId)
         if (res_alipay) {
          (window as any).AlipayJSBridge.call('popWindow');
          // naviagtor(`/ali_pay_result?paysn=${res.data.paysn}`)
         } else {
          naviagtor(`/ali_pay_result?paysn=${res.data.paysn}`)
         }
        }
      } else {
        Toast.show(res.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='oil-pay-con'>
      <div className='store-info'>
        <div className='left-con'>
          <span className='big-name'>{storeInfo.room.name}</span>
          <span className='min-name'>加油员<span style={{color:'#ff0000'}}>{storeInfo.emp.name}</span>正在为您服务</span>
        </div>
        <div className='right-con'>
          <img src={storeInfo.room.image} alt=''/>
        </div>
      </div>
      <div className='input-money' onClick={()=> setVisibleKey(true)}>
        <span className='left-text'>金额</span>
        <div className='right-text'>
          <div className='price'>{priceMoney}</div>
          <div className='line'></div>
          <span className='yuan'>元</span>
        </div>
      </div>
      <div className='bot-key'>
        <div className='bot-key-con'>
          {
            priceMoney && visibleKey && <div className='price-show'>
              <span>付款金额: <span>{priceMoney}</span> 元</span>
            </div>
          }
          {
            isShowStatus && <div onClick={()=> openCard()} className={[priceMoney ? 'card-active':'', 'open-card'].join(' ')}>
              <img src={openCardImg} alt='' />
            </div>
          }
          <NumberKeyboard
            visible={visibleKey}
            onClose={()=> setVisibleKey(false)}
            onInput={(val)=> inputVal(val)}
            onDelete={()=> delNumber()}
            onConfirm={()=> makeMoney()}
            customKey='.'
            closeOnConfirm={false}
            showCloseButton={false}
            confirmText='支付'
          />
        </div>
      </div>
    </div>
  )
}

export default AddOilPay