import React, {FC, useEffect, useState} from "react";
import { useMount, useDebounceFn } from 'ahooks'
import {List, Toast, Dialog, NumberKeyboard, Input, Popup, Button, Checkbox, SpinLoading} from 'antd-mobile'
import {getShopInfo, submitOrder, checkOrderPay, payOrder, getMyCouPonList, computeMoney} from '@api/index';
import StoreImage from '@assets/images/store.png';
import PayUtils from '@utils/pay_utils';
import openCardImg from '@assets/images/card_3.png';
import {getSessionStorage} from "@utils/my_session";
import {getStorage} from "@utils/my_storage";
import Tools from '@utils/tools'
import './index.scss'

const WeiXinPay: FC = () => {
  const urlParam = Tools.param2Obj(window.location.search)
  const [ModelStatus, setModelStatus] = useState(false)
  const [disStatus, setDisStatus] = useState(false)
  const apiUrl = process.env.REACT_APP_API_URL;
  const [value, setVal] = useState('')
  const [couponArr, setCouponArr] = useState<Array<any>>([])
  const [visibleKey, setVisibleKey] = useState(true)
  const [visibleLoading, setVisibleLoading] = useState(false)
  const [priceMoney, setPriceMoney] = useState('')
  const [coupon_ids, setCouPonIds] = useState('')
  const [store_info, setStoreInfo] = useState({
    id: 0,
    merchant_id: '',
    sub_name: '',
    name: '',
    logo: '',
  })

  const [computPrice, setComputPrice] = useState({
    dec_discount_money: 0,
    dec_coupon_money: 0,
    amount: 0,
    random_dec_discount_money: 0,
    random_discount_id: 0,
  })

  const  { random_dec_discount_money, dec_discount_money, dec_coupon_money, random_discount_id } = computPrice

  const { run } = useDebounceFn(
    () => {
      getCouponArr(`${store_info.id}`);
    },
    {
      wait: 600,
    },
  );

  useEffect( ()=> {
    run()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceMoney])

  useMount(()=> {
    getInitConfig()
  })

  // 不使用优惠券
  const noUsed = ()=> {
    setCouPonIds('')
    setModelStatus(false)
    couponArr.map(item=> item.check = false)
  }

  // 确认优惠券
  const makeCoupon = ()=> {
    let new_ids: Array<string> = []
    const arr = couponArr.filter(item => item.check)
    arr.map(item=> new_ids.push(item.id))
    if (arr.length>0) {
      setCouPonIds(new_ids.join(','))
    }
    setModelStatus(false)
  }

  const computeAmount = async (ids: string)=> {
    if (Number(priceMoney) === 0) return
    setVisibleLoading(true)
    const res = await computeMoney(urlParam.qrcode_id, priceMoney, ids)
    if (res.code === 200) {
      setComputPrice(res.data)
      setTimeout(()=> setVisibleLoading(false), 250)
    } else {
      setTimeout(()=> setVisibleLoading(false), 250)
    }
  }

  // 输入金额
  const inputVal = (val: string)=> {
    setPriceMoney(priceMoney+val)
    if (Number(priceMoney) > 0 && !Tools.checkMoneyStr(priceMoney)) {
      Toast.show('请输入正确金额')
      setPriceMoney('')
    }
  }

  // 删除money
  const delNumber = () => {
    Tools.vibrateShort()
    if (priceMoney.length > 0) {
      let price = priceMoney.substring(0, priceMoney.length - 1);
      setPriceMoney(price)
    }
  }

  // 支付金额校验 && 提交订单
  const makeMoney = ()=> {
    if (!Tools.checkMoneyStr(priceMoney)) {
      setPriceMoney('')
      return Toast.show('请输入正确金额!');
    }
    Toast.show({icon: 'loading', content: '请稍候...'})
    // 提交订单
    pushOrderInfo()
  }

  // 检查订单状态
  const checkOrderStatus = async (id: string)=> {
    try {
      const res = await checkOrderPay(id)
      if (res.code === 200) {
        userPayOrder(id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 获取配置信息
  const getInitConfig = async()=> {
    try {
      const { qrcode_id } = urlParam
      const info = await getShopInfo(qrcode_id)
      if (info.code === 200) {
        await setStoreInfo(info.data)
        await getCouponArr(info.data.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 提交订单
  const pushOrderInfo = async()=> {
    try {
      const { qrcode_id } = urlParam;
      const res = await submitOrder(qrcode_id, priceMoney, value, coupon_ids, random_discount_id, random_dec_discount_money)
      if (res.code === 200) {
        await checkOrderStatus(res.data.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 优惠券列表
  const getCouponArr = async (shop_id: string)=> {
    const env = Tools.getSystemPayEnv()
    if (env ==='ali' && priceMoney) {
      return await computeAmount('')
    }
    if (env ==='wx') {
      if (priceMoney && getSessionStorage('app_token')) {
        const res = await getMyCouPonList(shop_id, priceMoney)
        if (res.code === 200) {
          await setCouponArr(res.data.result)
          if (res.data.result.length>0) {
            let now_ids: Array<string> = []
            const arr = res.data.result.filter((item: any) => item.check)
            console.log(arr)
            arr.map((item: any) => now_ids.push(item.id))
            await setCouPonIds(now_ids.join(','))
            await computeAmount(now_ids.join(','))
          } else {
            await computeAmount('')
          }
        } else {
          await computeAmount('')
        }
      } else {
        await computeAmount('')
      }
    } else await computeAmount('')
  }

  // 支付订单
  const userPayOrder = async(orderid: string)=> {
    try {
      const env = Tools.getSystemPayEnv()
      const obj = {
        payment_code: env === 'wx' ? 'wxpay':'normal_allinpay',
        open_id: getStorage('open_id')
      }
      const res = await payOrder(orderid, env, obj.payment_code, getStorage('open_id'))
      if (res.code === 200) {
        if (env === 'wx') {
          await PayUtils.wxPay(res.data)
        } else {
          await PayUtils.aliPay(res.data.targetOrderId)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const openCard = ()=> {
    const redirectUrl = encodeURIComponent(window.location.href);
    const urlStr = `${apiUrl}/hyk/user.card/active_url?outer_str=${redirectUrl}`
    window.location.href = urlStr
  }

  const inputTextCon = ()=> {
    return (
      <Input id='text-con' onChange={val => setVal(val)} maxLength={20} placeholder='请输入给商家的留言信息,最多20字'/>
    )
  }

  const setUserTextCon = ()=> {
    // setVisibleKey(false)
    Dialog.show({
      content: inputTextCon(),
      closeOnAction: true,
      actions: [
        [
          {
            key: 'cancel',
            text: '取消',
            onClick: ()=> {
              setVal('')
            }
          },
          {
            key: 'delete',
            text: '确定',
            bold: true,
            danger: true,
          },
        ],
      ],
    })
  }

  const isShowOpenCard = ()=> {
    return !(getStorage('app_token') && getStorage('app_token') != '');
  }

  const changeCheckBox = async (index: number)=> {
    couponArr[index].check = !couponArr[index].check
    setCouponArr(couponArr)
    let now_ids: Array<string> = []
    const arr = couponArr.filter(item => item.check)
    arr.map(item=> now_ids.push(item.id))
    // await setCouPonIds(now_ids.join(','))
    await computeAmount(now_ids.join(','))
  }

  const CouponItem = (item: any, index: number): React.ReactNode=> {
    return (
      <div className='coupon_item' key={index}>
        <div className='coupon_item_main'>
          <div className='coupon_item_type'>{Number(item.coupon_info.dec_money)}</div>
          <div className='coupon_item_center'>
            <div className='coupon_item_card'>
              <div className='coupon_item_title'>{item.coupon_info.title}</div>
              <div className='coupon_item_exp_time'>优惠券长期有效</div>
              {/*<div className={'coupon-item-stepper'}>*/}
              {/*  <Stepper*/}
              {/*    max={item.max_use}*/}
              {/*    min={1}*/}
              {/*    disabled={!item.checked}*/}
              {/*    defaultValue={item.max_use}*/}
              {/*    // downStyle={'coupon-down'}*/}
              {/*    // inputStyle={'coupon-input'}*/}
              {/*    // upStyle={'coupon-up'}*/}
              {/*    onChange={(evt)=> chooseStepper(evt, index)}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div>
              <Checkbox checked={item.check} onChange={()=>changeCheckBox(index)}> </Checkbox>
            </div>
          </div>
        </div>
      </div>
    )
  }



  const disTextInfo = ()=> {
    const arr = []
    if (priceMoney) {
      if (dec_discount_money > 0) arr.push('门店折扣优惠')
      if (random_dec_discount_money > 0) arr.push('随机减免优惠')
      if (dec_coupon_money > 0) arr.push('优惠券优惠')
      if (arr.length === 0) arr.push('暂无优惠...')
      return <span style={{fontSize: '12px'}}>{arr.join(',')}</span>
    } else {
      arr.push('暂无优惠...')
      return <span style={{fontSize: '12px'}}>{arr.join(',')}</span>
    }
  }

  const couponInfo = ()=> {
    return (
      <Popup
        visible={ModelStatus}
        bodyStyle={{ height: '60vh', overflowY: 'auto'}}
        onMaskClick={()=>setModelStatus(false)}
      >
        <div className={'coupon-all'}>
          {
            couponArr.length === 0 ?
              <div className={'no-coupon'}>暂无优惠券</div>: couponArr.map((item, index) => {
                return CouponItem(item, index)
            })
          }
        </div>
        <div className={'bot-button'}>
          <Button color={'default'} block onClick={()=> noUsed()}>取消</Button>
          <Button color={'danger'} block onClick={()=> makeCoupon()}>确定</Button>
        </div>
      </Popup>
    )
  }

  const disMoneyInfo = ()=> {
    return (
      <Popup
        visible={disStatus}
        bodyStyle={{ height: '30vh', overflowY: 'auto'}}
        onMaskClick={()=>setDisStatus(false)}
      >
        <div className={'dis-title'}>优惠减免</div>
        <List>
          {
            random_dec_discount_money > 0 && <List.Item extra={`减免${random_dec_discount_money}元`}>
              <span className={'con-dis'}>享受随机减免优惠</span>
            </List.Item>
          }
          {
            dec_discount_money > 0 && <List.Item extra={`优惠${dec_discount_money}元`}>
              <span className={'con-dis'}>享受门店折扣优惠</span>
            </List.Item>
          }
          {
            dec_coupon_money > 0 && <List.Item extra={`优惠${dec_coupon_money}元`}>
              <span className={'con-dis'}>优惠券优惠</span>
            </List.Item>
          }
        </List>
      </Popup>
    )
  }

  return (
    <div className='wx-pay-con'>
      <div className='store-info'>
        <div className='left-con'>
          <span className='big-name'>{store_info.name}</span>
          <span className='min-name'>{store_info.sub_name}</span>
        </div>
        <div className='right-con'>
          <img src={StoreImage} alt=''/>
        </div>
      </div>
      <div className='input-money' onClick={()=> setVisibleKey(true)}>
        <span className='left-text'>金额</span>
        <div className='right-text'>
          <div className='price'>{priceMoney}</div>
          <div className='line'/>
          <span className='yuan'>元</span>
        </div>
      </div>
      {/* <div className='input-money'>
        <span className='left-text' style={ isUsedPoint ? {color: ''}:{color: '#cacaca'}}>使用10积分抵扣1元</span>
        <div>
          <Checkbox checked={isUsedPoint} onChange={ val => changeCheck(val)}></Checkbox>
        </div>
      </div> */}
      <List>
        {
          couponArr.length > 0 && <List.Item extra={couponArr.length>0?`有${couponArr.length}张券可用`:'暂无可用优惠券'} onClick={() => setModelStatus(true)}  description={<div style={{paddingLeft: '10px'}}>{value}</div>}>
            <span className='add-text'>优惠券</span>
          </List.Item>
        }

        <List.Item extra={disTextInfo()} onClick={()=> setDisStatus(true)} arrow>
          <span className='add-text'>减免优惠</span>
        </List.Item>
        <List.Item title={<span style={{paddingLeft: '10px', fontSize: '14px'}}>备注信息</span>} onClick={()=> setUserTextCon()} arrow extra={value}>
        </List.Item>
      </List>
      {/*优惠券*/}
      {couponInfo()}
      {/*优惠信息*/}
      {disMoneyInfo()}
      <div className='bot-key'>
        <div className='bot-key-con'>
          {
            priceMoney && visibleLoading && <div className={'price-show-loading'}>
              <span style={{ fontSize: 14 }}>
                <SpinLoading />
              </span>
              <span className={'jisuan-text'}>优惠计算中</span>
            </div>
          }

          {
            priceMoney && visibleKey && !visibleLoading && <div className='price-show'>
              <span style={{color: '#ff0000'}}>优惠: <span>{Number(computPrice.dec_coupon_money)+Number(computPrice.dec_discount_money)+Number(random_dec_discount_money)}</span> 元</span>
              <span>付款金额: <span>{computPrice.amount}</span> 元</span>
            </div>
          }
          {
            isShowOpenCard() && <div onClick={()=> openCard()} className={[priceMoney ? 'card-active':'', 'open-card'].join(' ')}>
              <img src={openCardImg} alt='' />
            </div>
          }
          <NumberKeyboard
            visible={visibleKey}
            onClose={()=> setVisibleKey(false)}
            onInput={(val)=> inputVal(val)}
            onDelete={()=> delNumber()}
            onConfirm={()=> makeMoney()}
            customKey='.'
            closeOnConfirm={false}
            showCloseButton={false}
            confirmText='支付'
          />
        </div>
      </div>
    </div>
  )
}

export default WeiXinPay