import {checkToken, getHykOpenid, getWxAuthUrl } from "@api/index";
import {getSessionStorage} from "@utils/my_session";
import { getStorage } from "./my_storage";

type PayEnv = "wx" | "ali" | "h5";

const apiUrl = process.env.REACT_APP_API_URL;

class Tools {
  // 支付环境判断
  getSystemPayEnv(): PayEnv {
    var payEnv: PayEnv;
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      payEnv = "wx";
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      payEnv = "ali";
    } else {
      payEnv = "h5";
    }
    return payEnv;
  }
  // 解析地址栏参数
  param2Obj(url: string) {
    const search = url.split("?")[1];
    if (!search) {
      return {};
    }
    return JSON.parse(
      '{"' +
        decodeURIComponent(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')
          .replace(/\+/g, " ") +
        '"}'
    );
  }

  // 存储本地缓存
  initUserInfo = () => {
    try {
      const urlParam = this.param2Obj(window.location.href);
      const keysArr = Object.keys(urlParam);
      if (keysArr.length > 0) {
        keysArr.map((item) => {
          localStorage.setItem(item, urlParam[item])
          return sessionStorage.setItem(item, urlParam[item])
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 校验金额
  checkMoneyStr(val: string): boolean {
    const MaxMoney = 99999.99;
    const str = val.toString();
    // 金额不能为0
    if (!Number(str)) return false;
    // 首位只有一个 0
    if (str.substr(0, 2) === "00") return false;
    // 首位禁止小数
    if (str.substring(0, 1) === ".") return false;
    // 小数点
    if (str.indexOf(".") > -1) {
      // 禁止两个小数点输入
      if (str.split(".").length > 2) return false;
      // 小数后最多输入两位
      if (str.split(".")[1].length > 2) return false;
    }
    // 最大限额
    if (Number(str) > MaxMoney) return false;
    return true;
  }
  // 短振动
  vibrateShort() {
    if ('vibrate' in window.navigator) {
      window.navigator.vibrate(100);
    }
  }

  // 获取支付宝 auth_code
  async getAliPayCode(appId: string) : Promise<any> {
    // 2021003116666628 加油站
    // 2021003117624652 汇悦客
    return new Promise((resolve)=> {
      (window as any).ap.getAuthCode ({
        appId,
        scopes: ['auth_base'],
      }, function(res: any) {
        // (window as any).ap.alert (JSON.stringify(res));
        resolve(res)
      })
    })
  }

  // 获取微信支付 code
  async getWeiXinCode() {
    const redirectUrl = encodeURIComponent(window.location.href);
    const apiUrl = process.env.REACT_APP_API_URL;
    const obj = this.param2Obj(window.location.search);
    let app_id = obj.app_id;
    if (!app_id) app_id = obj.appid;
    // 开卡回调
    if (obj.hasOwnProperty('time')) return false
    if (getStorage('app_token')) {
      const status = await checkToken()
      if (status.code === 200) {
        return  false
      } else {
        const res = await getWxAuthUrl(redirectUrl)
        if (res.code === 200) {
          window.location.href = res.data.url
        }
      }
    }
    if (obj.hasOwnProperty('code')) {
      const info = await getHykOpenid(obj.code)
      if (info.code === 200) {
         localStorage.setItem('app_token', info.data.app_token);
         localStorage.setItem('open_id', info.data.open_id);
         return false
      }
    } else {
      const res = await getWxAuthUrl(redirectUrl)
      if (res.code === 200) {
        window.location.href = res.data.url
      }
    }
    // const wxUrl = `${apiUrl}/hyk/user.merchant_shop_qrcode_order/auth?ext_appid=wx5daf4db5d5b01212&callback=${redirectUrl}`;
    // if (obj.time) {
    //   return this.initUserInfo();
    // }
  }

  getOilUserLoginWxToken(obj: any) {
    const redirectUrl = encodeURIComponent(window.location.href);
    let app_id = obj.app_id;
    if (!app_id) app_id = obj.appid;
    const wxUrl = `${apiUrl}/client/wechat.login/href_url?app_id=${app_id}&callback=${redirectUrl}`;
    if (getSessionStorage('wx_token')) return false
    else window.location.href = wxUrl;
  }

}

export default new Tools();
