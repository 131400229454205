import React, { FC } from "react";
import { useMount } from "ahooks";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import WeiXinPay from "@view/wxpay/index";
import Tools from '@utils/tools'
import AddOilPay from "@view/oilpay";
import RechargePay from "@view/recharge/index";
import PayResultOrder from "@view/pay_result";
import AliPayResultOrder from "@view/ali_pay_result";

import {getStorage} from "@utils/my_storage";
import {aliUserLogin, hykAliLogin} from "@api/index";
import {setSessionStorage} from "@utils/my_session";
import "./App.scss";

const App: FC = ()=> {

  useMount(async ()=> {
    Tools.initUserInfo()
    const obj = Tools.param2Obj(window.location.search)
    if (Tools.getSystemPayEnv() === 'wx') {
      if (getStorage('oil_status')) {
        // 加油站是否请示开卡二维码支付
        Tools.getOilUserLoginWxToken(obj)
      } else {
        // 汇悦客 扫码支付
        await Tools.getWeiXinCode()
      }
    } else {
      const status = obj.hasOwnProperty('oil_status')
      const code_info = await Tools.getAliPayCode(status ? '2021003116666628':'2021003117624652')
      if (code_info.authCode) {
        if (status) {
          const info = await aliUserLogin(obj.app_id, code_info.authCode, `2021003116666628`)
          if (info.code && info.code !== 400) {
            setSessionStorage('ali_token', info.data.ali_token)
          }
        } else {
          // hyk 支付宝付款
          const ali_info = await hykAliLogin(code_info.authCode)
          if (ali_info.code === 200) {
            setSessionStorage('user_id', ali_info.data.user_id)
          }
        }
        // setSessionStorage('authCode', code_info.authCode)
      }
    }
  })

  return (
    <Router basename={'/'}>
      <Routes>
        <Route path="/" element={<WeiXinPay />} />
        <Route path="/hyk_pay" element={<WeiXinPay />} />
        <Route path="/oil_pay" element={<AddOilPay />} />
        <Route path="/recharge_pay" element={<RechargePay />} />
        <Route path="/pay_result" element={<PayResultOrder />} />
        <Route path="/ali_pay_result" element={<AliPayResultOrder />} />
      </Routes>
    </Router>
  )
}


export default App;
